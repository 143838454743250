/********************************************************
 * CORE STYLE
 ********************************************************/
/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

@font-face {
  font-family: 'LH Light';
  src: url("/fonts/LufthansaTextWeb-Light.eot?#iefix");
  src: url("/fonts/LufthansaTextWeb-Light.eot?#iefix") format("eot"), url("/fonts/LufthansaTextWeb-Light.woff") format("woff"), url("/fonts/LufthansaTextWeb-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'LH Light-Head';
  src: url("/fonts/LufthansaHeadWeb-Light.eot?#iefix");
  src: url("/fonts/LufthansaHeadWeb-Light.eot?#iefix") format("eot"), url("/fonts/LufthansaHeadWeb-Light.woff") format("woff"), url("/fonts/LufthansaHeadWeb-Light.ttf") format("truetype");
  font-weight: 400;
  font-style: normal; }

@font-face {
  font-family: 'LH Medium';
  src: url("/fonts/LufthansaTextWeb-Bold.eot?#iefix");
  src: url("/fonts/LufthansaTextWeb-Bold.eot?#iefix") format("eot"), url("/fonts/LufthansaTextWeb-Bold.woff") format("woff"), url("/fonts/LufthansaTextWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'LH Medium-Head';
  src: url("/fonts/LufthansaHeadWeb-Bold.eot?#iefix");
  src: url("/fonts/LufthansaHeadWeb-Bold.eot?#iefix") format("eot"), url("/fonts/LufthansaHeadWeb-Bold.woff") format("woff"), url("/fonts/LufthansaHeadWeb-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

@font-face {
  font-family: 'LH Icon';
  src: url("/fonts/lh-iconfont.eot?#iefix");
  src: url("/fonts/lh-iconfont.eot?#iefix") format("eot"), url("/fonts/lh-iconfont.woff") format("woff"), url("/fonts/lh-iconfont.ttf") format("truetype");
  font-weight: 700;
  font-style: normal; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

::-webkit-input-placeholder {
  color: #dcdcdc;
  opacity: 1; }

:-moz-placeholder {
  color: #dcdcdc;
  opacity: 1; }

::-moz-placeholder {
  color: #dcdcdc;
  opacity: 1; }

:-ms-input-placeholder {
  color: #dcdcdc;
  opacity: 1; }

.form {
  width: 100%;
  display: block;
  position: relative; }
  .form__row {
    margin-left: -7.5px;
    margin-right: -7.5px;
    margin-bottom: 10px; }
    .form__row:after {
      content: '';
      display: block;
      clear: both; }
    .form__row:last-of-type {
      margin-bottom: 0; }
      .form__row:last-of-type .form__field:last-of-type {
        margin-bottom: 0; }
  .form .help-block-error,
  .form .help-block {
    color: #d9534f;
    font-size: 14px; }
  .form label {
    display: block;
    margin: 0 0 2px 0;
    font-size: 14px; }
    .form label span.optional {
      color: #dcdcdc; }
  .form .small {
    color: #d9534f; }
    .form .small .bold {
      font-weight: bold; }
  .form select {
    height: 39px; }
  .form button {
    margin-top: 10px; }
  .form textarea {
    height: 200px;
    resize: none; }
  .form .form__radio-label {
    display: inline-block;
    margin: 0 10px 0 0;
    float: left; }
    .form .form__radio-label:last-of-type {
      margin-right: 0; }
  .form select, .form textarea, .form input:not([type]), .form input[type="text"], .form input[type="password"], .form input[type="datetime"], .form input[type="datetime-local"], .form input[type="date"], .form input[type="month"], .form input[type="time"], .form input[type="week"], .form input[type="number"], .form input[type="email"], .form input[type="url"], .form input[type="search"], .form input[type="tel"], .form input[type="color"] {
    width: 100%;
    background-color: #fff;
    border-radius: 4px;
    border: 1px solid #dcdcdc;
    padding: 10px 14px;
    color: #000000;
    font-weight: bold;
    transition: background-color 300ms, border-color 300ms; }
    .form select:focus, .form textarea:focus, .form input:not([type]):focus, .form input[type="text"]:focus, .form input[type="password"]:focus, .form input[type="datetime"]:focus, .form input[type="datetime-local"]:focus, .form input[type="date"]:focus, .form input[type="month"]:focus, .form input[type="time"]:focus, .form input[type="week"]:focus, .form input[type="number"]:focus, .form input[type="email"]:focus, .form input[type="url"]:focus, .form input[type="search"]:focus, .form input[type="tel"]:focus, .form input[type="color"]:focus {
      border-color: #5bc0de;
      outline: none; }
  .form__field {
    width: calc(100% - 15px);
    margin-left: 7.5px;
    margin-right: 7.5px;
    float: left; }
    .form__field label {
      display: inline-block;
      width: 100%; }
    .form__field--1-2 {
      width: calc(50% - 15px);
      margin-left: 7.5px;
      margin-right: 7.5px;
      float: left; }
    .form__field--1-3 {
      width: calc(33.33333% - 15px);
      margin-left: 7.5px;
      margin-right: 7.5px;
      float: left; }
    .form__field--1-4 {
      width: calc(25% - 15px);
      margin-left: 7.5px;
      margin-right: 7.5px;
      float: left; }

.button {
  font-family: Arial, sans-serif;
  padding: 3px  20px;
  height: auto;
  border-radius: 5px;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.3;
  cursor: pointer;
  transition: .5s;
  box-sizing: border-box;
  margin-right: 20px;
  background-color: #05164d;
  color: #FFF;
  text-decoration: none;
  text-align: center;
  min-width: 80px;
  border: none; }
  .button:hover {
    text-decoration: none;
    background-color: #009;
    color: #FFF; }
  .button svg {
    height: 20px;
    width: 20px; }
    .button svg * {
      fill: #fff; }
  .button--anchor {
    height: auto;
    padding: 15px;
    border: 0;
    background: transparent;
    color: #d9534f;
    cursor: pointer; }
    .button--anchor:hover {
      text-decoration: none; }
  .button--processed {
    background-color: #f0ad4e;
    color: #FFF; }
  .button--new {
    background-color: #5bc0de;
    color: #FFF; }
  .button--back svg {
    height: 15px;
    width: 15px;
    margin-bottom: -2px; }
  .button--submit, .button--done {
    background-color: #5cb85c;
    border-color: transparent;
    color: #FFF; }
  .button--download_empty {
    cursor: default; }
    .button--download_empty:hover {
      background-color: #fff;
      color: #05164d; }
  .button--danger {
    background-color: #d9534f;
    border-color: transparent;
    color: #FFF; }
  .button--edit {
    background-color: #05164d;
    color: #FFF; }
  .button--disabled {
    cursor: default;
    background-color: #e6e6e6;
    color: #666666;
    border-color: #cfcfcf; }
    .button--disabled:hover {
      background-color: #e6e6e6;
      color: #666666; }
  .button--secondary {
    background-color: #fff;
    color: #05164d;
    border: 1px solid;
    border-color: #05164d; }
  .button--add-to-collection {
    background-color: #ffad00;
    color: #FFF; }
  .button--blue {
    background-color: #05164d;
    color: #FFF; }
    .button--blue:hover {
      background-color: #009;
      color: #FFF; }
  .button--with-icon svg {
    height: 15px;
    width: 15px;
    vertical-align: -2px; }
    .button--with-icon svg path {
      fill: currentColor; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
body {
  background: #FFF;
  position: relative;
  font-family: 'LH Light';
  color: #333;
  margin: 0; }
  body * {
    box-sizing: border-box; }

.container {
  position: relative;
  max-width: 765px;
  margin: 0;
  min-height: 100vh; }

.section {
  margin-bottom: 50px; }

button, button:hover, a, a:hover {
  transition: .5s; }

/********************************************************
 * COMPONENT STYLE
 ********************************************************/
/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.bubble {
  display: inline-block;
  padding: 4px 25px;
  color: #05164d;
  font-weight: bold;
  margin-right: 5px;
  margin-bottom: 5px;
  font-size: 14px;
  border-radius: 20px;
  border: 1px solid #05164d; }
  .bubble:last-of-type {
    margin-right: 0; }
  .bubble--tag {
    background: transparent; }
  .bubble--category {
    background: #666;
    border: 0 none;
    color: #fff; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.category-container {
  height: 277px;
  font-size: 15px; }
  .category-container h2 {
    margin: 0 20px;
    font-size: 26px;
    margin-top: 17px;
    color: #05164d; }
  .category-container .category-box {
    margin: 0 -20px;
    position: relative;
    -webkit-user-select: none;
    /* Chrome/Safari */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* IE10+ */
    -o-user-select: none;
    user-select: none;
    min-height: 200px; }
    .category-container .category-box__download-overlay {
      display: none;
      position: absolute;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.5);
      z-index: 999999; }
      .category-container .category-box__download-overlay svg {
        width: 110px !important;
        height: 110px !important;
        bottom: 50px !important;
        right: 60px !important; }
        .category-container .category-box__download-overlay svg * {
          fill: #FFF !important; }
    .category-container .category-box__updated {
      background: #ffad00;
      height: 30px;
      width: 150px;
      display: inline-block;
      position: absolute;
      left: -36px;
      bottom: 20px;
      transform: rotate(45deg); }
      .category-container .category-box__updated span {
        color: #FFF;
        font-size: 14px;
        font-weight: 700; }
      .category-container .category-box__updated span.category-box__updated--icon svg {
        width: 20px;
        height: 20px;
        position: relative;
        bottom: inherit;
        right: inherit;
        margin-left: 30px;
        vertical-align: -6px;
        margin-top: 4px; }
        .category-container .category-box__updated span.category-box__updated--icon svg * {
          fill: #FFF; }
    .category-container .category-box div.flickity-viewport {
      height: 255px !important; }
    .category-container .category-box__flickity {
      position: relative;
      width: calc(100% - 40px);
      overflow: hidden;
      margin: 0 auto;
      outline: none;
      z-index: 1; }
      .category-container .category-box__flickity--prev, .category-container .category-box__flickity--next {
        cursor: pointer;
        top: 140px;
        position: absolute;
        z-index: 2;
        background: rgba(255, 255, 255, 0.8);
        width: 40px;
        height: 40px;
        padding: 5px; }
        .category-container .category-box__flickity--prev:hover svg *, .category-container .category-box__flickity--next:hover svg * {
          fill: #009; }
        .category-container .category-box__flickity--prev svg, .category-container .category-box__flickity--next svg {
          width: 30px;
          height: 30px; }
          .category-container .category-box__flickity--prev svg *, .category-container .category-box__flickity--next svg * {
            fill: #999; }
      .category-container .category-box__flickity--next {
        right: 20px; }
      .category-container .category-box__flickity--prev {
        left: 20px; }
    .category-container .category-box__item {
      cursor: pointer;
      border-radius: 10px;
      overflow: hidden;
      display: inline-block;
      position: relative;
      height: 230px;
      width: calc(22% - 14px);
      background-color: #f5f5f5;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 14px;
      margin-top: 14px;
      text-align: left; }
      .category-container .category-box__item:hover {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.35);
        transition: all 500ms ease-in-out;
        top: -5px; }
        .category-container .category-box__item:hover .category-box__download-overlay {
          display: block; }
      .category-container .category-box__item img {
        position: absolute;
        top: 0;
        height: 100%;
        width: 100%;
        z-index: 1; }
      .category-container .category-box__item svg {
        width: 270px;
        height: 270px;
        position: absolute;
        bottom: -80px;
        right: -80px; }
        .category-container .category-box__item svg * {
          fill: #05164d; }
    .category-container .category-box__head {
      padding: 12px 16px;
      width: 100%;
      z-index: 10;
      position: absolute; }
    .category-container .category-box__title {
      font-size: 18px;
      font-weight: bold; }
    .category-container .category-box__count {
      font-size: 18px; }
    .category-container .category-box__title, .category-container .category-box__count {
      display: block;
      color: #fff; }
    .category-container .category-box__title {
      font-family: 'LH Medium-Head'; }
    .category-container .category-box__count {
      font-weight: 200;
      margin-top: -2px;
      font-family: 'LH Light-Head'; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.select-box {
  position: relative;
  width: 100%; }
  .select-box__selected {
    cursor: pointer;
    font-size: 18px;
    color: #05164d; }
    .select-box__selected svg {
      width: 20px;
      height: 20px;
      vertical-align: -5px;
      margin-left: 5px; }
      .select-box__selected svg * {
        fill: #05164d; }
  .select-box__option {
    font-family: 'LH Light-Head';
    display: inline-block;
    width: calc(33%);
    text-align: center;
    position: relative;
    cursor: pointer;
    padding-bottom: 5px; }
    .select-box__option span {
      color: #d8d8d8;
      font-size: 18px;
      padding: 0 15px; }
    .select-box__option:last-of-type {
      margin-bottom: 0;
      margin-right: 0; }
    .select-box__option.is-active {
      font-weight: bold; }
    .select-box__option.is-active, .select-box__option:hover {
      border-bottom: 3px solid #05164d;
      font-family: 'LH Medium-Head'; }
      .select-box__option.is-active span, .select-box__option:hover span {
        color: #05164d; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.loading-container {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  text-align: center;
  width: 100%; }
  .loading-container > span {
    font-size: 16px;
    font-weight: bold; }
  .loading-container #lh-loading {
    position: relative;
    width: 200px;
    height: 15px;
    margin: auto; }
  .loading-container .small_text_regular {
    font-family: "Arial";
    font-size: 14px;
    line-height: 18px;
    color: #333; }
  .loading-container .center {
    margin: auto;
    text-align: center;
    font-size: 14px;
    vertical-align: middle;
    width: 200px; }
  .loading-container .lh-loading {
    position: absolute;
    top: 0;
    background-color: gainsboro;
    width: 12px;
    height: 12px;
    animation-name: bounce_lh-loading;
    -o-animation-name: bounce_lh-loading;
    -ms-animation-name: bounce_lh-loading;
    -webkit-animation-name: bounce_lh-loading;
    -moz-animation-name: bounce_lh-loading;
    animation-duration: 1.5s;
    -o-animation-duration: 1.5s;
    -ms-animation-duration: 1.5s;
    -webkit-animation-duration: 1.5s;
    -moz-animation-duration: 1.5s;
    animation-iteration-count: infinite;
    -o-animation-iteration-count: infinite;
    -ms-animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -moz-animation-iteration-count: infinite;
    animation-direction: normal;
    -o-animation-direction: normal;
    -ms-animation-direction: normal;
    -webkit-animation-direction: normal;
    -moz-animation-direction: normal;
    border-radius: 12px;
    -o-border-radius: 12px;
    -ms-border-radius: 12px;
    -webkit-border-radius: 12px;
    -moz-border-radius: 12px;
    transform: scale(0.6);
    -o-transform: scale(0.6);
    -ms-transform: scale(0.6);
    -webkit-transform: scale(0.6);
    -moz-transform: scale(0.6); }
  .loading-container #lh-loading_1 {
    left: 0;
    animation-delay: 0.6s;
    -o-animation-delay: 0.6s;
    -ms-animation-delay: 0.6s;
    -webkit-animation-delay: 0.6s;
    -moz-animation-delay: 0.6s; }
  .loading-container #lh-loading_2 {
    left: 25px;
    animation-delay: 0.75s;
    -o-animation-delay: 0.75s;
    -ms-animation-delay: 0.75s;
    -webkit-animation-delay: 0.75s;
    -moz-animation-delay: 0.75s; }
  .loading-container #lh-loading_3 {
    left: 50px;
    animation-delay: 0.9s;
    -o-animation-delay: 0.9s;
    -ms-animation-delay: 0.9s;
    -webkit-animation-delay: 0.9s;
    -moz-animation-delay: 0.9s; }
  .loading-container #lh-loading_4 {
    left: 75px;
    animation-delay: 1.05s;
    -o-animation-delay: 1.05s;
    -ms-animation-delay: 1.05s;
    -webkit-animation-delay: 1.05s;
    -moz-animation-delay: 1.05s; }
  .loading-container #lh-loading_5 {
    left: 100px;
    animation-delay: 1.2s;
    -o-animation-delay: 1.2s;
    -ms-animation-delay: 1.2s;
    -webkit-animation-delay: 1.2s;
    -moz-animation-delay: 1.2s; }
  .loading-container #lh-loading_6 {
    left: 125px;
    animation-delay: 1.35s;
    -o-animation-delay: 1.35s;
    -ms-animation-delay: 1.35s;
    -webkit-animation-delay: 1.35s;
    -moz-animation-delay: 1.35s; }
  .loading-container #lh-loading_7 {
    left: 150px;
    animation-delay: 1.5s;
    -o-animation-delay: 1.5s;
    -ms-animation-delay: 1.5s;
    -webkit-animation-delay: 1.5s;
    -moz-animation-delay: 1.5s; }
  .loading-container #lh-loading_8 {
    left: 175px;
    animation-delay: 1.64s;
    -o-animation-delay: 1.64s;
    -ms-animation-delay: 1.64s;
    -webkit-animation-delay: 1.64s;
    -moz-animation-delay: 1.64s; }
  .loading-container #lh-loading_9 {
    left: 200px;
    animation-delay: 1.8s;
    -o-animation-delay: 1.8s;
    -ms-animation-delay: 1.8s;
    -webkit-animation-delay: 1.8s;
    -moz-animation-delay: 1.8s; }

@keyframes bounce_lh-loading {
  0% {
    transform: scale(0.6);
    background-color: gainsboro; }
  33% {
    transform: scale(1);
    background-color: #ffb300; }
  100% {
    transform: scale(0.6);
    background-color: gainsboro; } }

@-o-keyframes bounce_lh-loading {
  0% {
    -o-transform: scale(0.6);
    background-color: gainsboro; }
  33% {
    -o-transform: scale(1);
    background-color: #ffb300; }
  100% {
    -o-transform: scale(0.6);
    background-color: gainsboro; } }

@-ms-keyframes bounce_lh-loading {
  .loading-container 0% {
    -ms-transform: scale(0.6);
    background-color: gainsboro; }
  .loading-container 33% {
    -ms-transform: scale(1);
    background-color: #ffb300; }
  .loading-container 100% {
    -ms-transform: scale(0.6);
    background-color: gainsboro; } }

@-webkit-keyframes bounce_lh-loading {
  0% {
    -webkit-transform: scale(0.6);
    background-color: gainsboro; }
  33% {
    -webkit-transform: scale(1);
    background-color: #ffb300; }
  100% {
    -webkit-transform: scale(0.6);
    background-color: gainsboro; } }

@-moz-keyframes bounce_lh-loading {
  0% {
    -moz-transform: scale(0.6);
    background-color: gainsboro; }
  33% {
    -moz-transform: scale(1);
    background-color: #ffb300; }
  100% {
    -moz-transform: scale(0.6);
    background-color: gainsboro; } }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

body .overlay {
  position: absolute;
  height: 100%;
  width: 100%;
  z-index: 999;
  background: rgba(255, 255, 255, 0.9); }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.svgitem-modal {
  position: fixed;
  width: 100%;
  max-width: 760px;
  left: 5px;
  background: #FFF;
  z-index: 10000;
  top: 30%;
  transform: translateY(-30%);
  box-shadow: 0px 0px 13px rgba(0, 0, 0, 0.2); }
  .svgitem-modal__close {
    position: absolute;
    right: 0px;
    top: -45px;
    z-index: 10001;
    cursor: pointer; }
    .svgitem-modal__close svg {
      width: 30px;
      height: 30px; }
      .svgitem-modal__close svg * {
        fill: #05164d; }
  .svgitem-modal__bubbles {
    min-height: 95px; }
  .svgitem-modal__selectbox {
    position: relative; }
  .svgitem-modal__selectbox-border-bottom {
    display: inline-block;
    border-bottom: 1px solid #d8d8d8;
    width: 100%;
    position: absolute;
    left: 0;
    bottom: 1px; }
  .svgitem-modal__button {
    width: 100%;
    height: auto;
    padding: 10px;
    border-radius: 5px;
    font-weight: bold;
    position: relative;
    display: block;
    padding: 15px 40px;
    font-size: 18px;
    margin-bottom: 5px; }
    .svgitem-modal__button:last-of-type {
      margin-bottom: 0; }
  .svgitem-modal__buttons {
    position: relative;
    width: 100%;
    margin-top: 25px; }
    .svgitem-modal__buttons button {
      border: 1px solid #05164d; }
      .svgitem-modal__buttons button[name="downloadArchive"] {
        color: #05164d;
        background: transparent; }
        .svgitem-modal__buttons button[name="downloadArchive"]:hover {
          color: #009;
          border-color: #009; }
  .svgitem-modal__container {
    position: relative;
    padding: 40px; }
  .svgitem-modal__grid-row {
    display: grid;
    grid-template-columns: 1fr 1fr; }
  .svgitem-modal__side {
    position: relative; }
    .svgitem-modal__side:first-of-type {
      margin-right: 65px;
      display: grid;
      grid-template-rows: 1fr auto;
      -ms-grid-column: 1;
      -ms-grid-row: 1; }
    .svgitem-modal__side:last-of-type {
      -ms-grid-column: 2;
      -ms-grid-row: 1; }
      .svgitem-modal__side:last-of-type[data-background="show"] {
        background: url("/images/free.jpg") repeat; }
  .svgitem-modal__title {
    font-size: 32px;
    margin-bottom: 20px;
    margin-top: -8px;
    color: #05164d; }
  .svgitem-modal__sub-title {
    font-size: 16px;
    font-weight: 200;
    display: block; }
  .svgitem-modal__xml-container {
    display: grid;
    grid-template-rows: 1fr auto;
    height: 100%; }
  .svgitem-modal__masterxml {
    width: 100%;
    -ms-grid-column: 1;
    -ms-grid-row: 1; }
  .svgitem-modal__childxml {
    width: 100%;
    padding: 0 20px;
    padding-bottom: 17px;
    -ms-grid-column: 2;
    -ms-grid-row: 1; }
  .svgitem-modal__new {
    display: block;
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    top: -19px;
    background: #ffad00;
    right: -19px; }
    .svgitem-modal__new span {
      font-family: 'LH Medium-Head';
      display: inline-block;
      color: #FFF;
      font-size: 14px;
      font-weight: bold;
      top: 50%;
      position: absolute;
      transform: translate3d(-50%, -50%, 0);
      left: 50%;
      font-weight: bold; }
  .svgitem-modal__svg[data-class="master"] {
    padding: 20px;
    height: 100%;
    display: grid;
    align-items: center; }
    .svgitem-modal__svg[data-class="master"] svg {
      width: 100%;
      height: auto;
      max-height: 250px; }
  .svgitem-modal__svg[data-class="child"] {
    display: inline-block;
    margin-right: 10px;
    width: 30px;
    height: 30px;
    cursor: pointer;
    border-radius: 50%;
    position: relative; }
    .svgitem-modal__svg[data-class="child"]:first-of-type svg {
      transform: rotate(45deg); }
    .svgitem-modal__svg[data-class="child"]:last-of-type {
      margin-right: 0; }
    .svgitem-modal__svg[data-class="child"]:hover, .svgitem-modal__svg[data-class="child"][data-active="is-active"] {
      background: #FFF;
      box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.5); }
    .svgitem-modal__svg[data-class="child"] svg {
      width: 30px;
      height: 30px;
      padding: 5px; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

div.search-bar-container {
  position: fixed;
  width: 100%;
  max-width: 765px;
  min-height: 80px;
  z-index: 998;
  overflow: hidden; }

.search-bar {
  background: #FFF;
  min-height: 50px;
  padding: 2px 110px 2px 50px;
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 765px;
  box-shadow: 0px 1px 13px rgba(0, 0, 0, 0.1); }
  .search-bar[data-border="bordered"] {
    border: 1px solid #05164d; }
  .search-bar input {
    height: 60px;
    font-size: 20px;
    margin-left: 10px;
    border: 0 none;
    background: transparent;
    outline: none;
    display: block;
    float: left;
    box-sizing: content-box; }
  .search-bar ::-webkit-input-placeholder {
    color: #ccc;
    font-size: 20px;
    font-family: 'LH Light'; }
  .search-bar .hidden_search {
    display: block;
    float: left; }
  .search-bar__zoom {
    cursor: pointer;
    position: absolute;
    left: 0px;
    top: 50%;
    z-index: 9999;
    transform: translateY(-50%);
    z-index: 9999; }
    .search-bar__zoom svg {
      width: 35px;
      height: 35px; }
      .search-bar__zoom svg * {
        fill: #ccc; }
    .search-bar__zoom.is_active svg * {
      fill: #05164d; }
  .search-bar__reset {
    cursor: pointer;
    position: absolute;
    right: 27px;
    top: 50%;
    z-index: 9999;
    transform: translateY(-50%);
    z-index: 9999; }
    .search-bar__reset svg {
      width: 24px;
      height: 24px; }
      .search-bar__reset svg * {
        fill: #ccc; }
  .search-bar__item {
    display: inline-block;
    position: relative;
    border-radius: 40px;
    padding: 8px 30px 4px 16px;
    margin-right: 2px;
    height: 40px;
    font-size: 20px;
    font-weight: bold;
    line-height: 1; }
    .search-bar__item:last-of-type {
      margin-right: 0; }
    .search-bar__item--tag {
      margin-top: 10px;
      background: transparent;
      color: #05164d;
      border: 1px solid #05164d; }
      .search-bar__item--tag svg {
        position: absolute;
        top: 10px;
        right: 8px;
        cursor: pointer;
        width: 15px;
        height: 15px; }
        .search-bar__item--tag svg * {
          fill: #05164d; }

/********************************************************
 * TEMPLATE STYLE
 ********************************************************/
/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.svg {
  position: relative;
  padding-top: 78px;
  z-index: 99; }

.sort-by {
  cursor: pointer;
  margin: 0 5px; }
  .sort-by:hover, .sort-by.active {
    color: #05164d;
    font-family: 'LH Medium'; }

.svg-meta__side {
  display: inline-block;
  width: 50%;
  font-size: 16px; }
  .svg-meta__side h2 {
    font-size: 26px;
    color: #05164d;
    margin-bottom: 0;
    margin-top: 54px; }
  .svg-meta__side p {
    font-size: 16px;
    margin-top: 0; }
  .svg-meta__side:last-of-type {
    text-align: right; }

.svg-grid {
  margin: 0 auto;
  max-width: 765px;
  position: relative;
  min-height: 450px; }
  .svg-grid h2 {
    margin-top: 0;
    padding-top: 20px; }
  .svg-grid__nothing-found {
    min-height: 300px;
    position: relative; }
    .svg-grid__nothing-found h2 {
      color: #05164d;
      margin: 0;
      font-size: 26px; }
    .svg-grid__nothing-found p {
      font-family: 'LH Light-Head';
      margin-top: 0;
      font-size: 26px;
      color: #05164d; }
    .svg-grid__nothing-found svg {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translateX(-50%); }
  .svg-grid__item {
    cursor: pointer;
    width: calc(100% / 7);
    padding: 10px;
    background: transparent; }
    .svg-grid__item--2 {
      width: calc(100% / 3.5); }
    .svg-grid__item:hover {
      background: #FFF;
      box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.2);
      border-radius: 10px; }
    .svg-grid__item--image svg {
      width: 100%;
      height: auto; }
  .svg-grid__items {
    padding: 20px 5px; }
  .svg-grid__new {
    position: absolute;
    right: 5px;
    width: 15px;
    height: 15px;
    top: 5px; }
    .svg-grid__new span {
      display: inline-block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      background: #ffad00; }

/****************************************
 * Used to preserve ratio of an element
 ****************************************/
/****************************************
 * Helpers
 ****************************************/
/****************************************
 * BEM Syntax
 * http://stackoverflow.com/questions/32052836/sass-bem-avoid-modifier-duplication-when-element-is-inside-a-modifier
 ****************************************/
/****************************************
 * Grid system
 ****************************************/
/* ==========================================================================
   Grid gutters and Container configs
   ========================================================================== */
/* ==========================================================================
   Font-Sizes
   ========================================================================== */
/* ==========================================================================
   Colors
   ========================================================================== */
/* ==========================================================================
   Forms
   ========================================================================== */
/* ==========================================================================
   Animations
   ========================================================================== */
@keyframes sxs-pulse {
  0% {
    opacity: 1; }
  50% {
    opacity: 0.5; }
  100% {
    opacity: 1; } }

@keyframes sxs-rotation {
  from {
    transform: rotate(0deg); }
  to {
    transform: rotate(359deg); } }

.login {
  width: 500px;
  margin: 50px auto;
  border-radius: 5px;
  background: #f5f5f5; }
  .login__head {
    background: #1e1e1e;
    border-radius: 5px 5px 0 0;
    color: #3899ea;
    padding: 15px 10px;
    font-size: 25px; }
  .login__form {
    padding: 0 10px 15px 10px;
    color: #333; }
    .login__form button {
      width: 100%;
      padding: 10px 15px;
      font-size: 17px; }
    .login__form input {
      font-size: 15px; }
  .login__loading {
    position: relative;
    height: 40px;
    margin-top: 5px; }
  .login__title {
    font-family: 'LH Medium-Head';
    margin-bottom: 10px;
    padding: 5px 0;
    font-size: 25px; }
  .login__error {
    background: #ffdad9;
    border: 1px solid #d9534f;
    color: #d9534f;
    padding: 15px;
    margin-bottom: 20px; }
    .login__error span {
      font-family: 'LH Medium';
      margin-bottom: 10px;
      font-weight: bold;
      display: inline-block; }
  .login__error-item {
    margin-bottom: 5px; }
